.ContactBlurb {
  width: 800px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.ContactContainer {
  margin-left: 30px;
  border-radius: 50%;
}

.ContactContainer:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px rgba(160, 101, 202, 0.4);
}

.ContactIcon {
  width: 40px;
  height: auto;
  filter: grayscale(20%);
}

.ContactIcon:hover {
  filter: none;
}

.ContactIconLichessContainer {
  margin-left: 30px;
}

.ContactIconLichess {
  width: 40px;
  height: auto;
  filter: none;
}

.ContactIconLichess:hover {
  filter: drop-shadow(10px 0 5px rgba(0, 0, 0, 0.5));
}
