.ProfilePhoto {
  height: auto;
  width: 160px;
  border-radius: 50%;
}

.ProfileContainer {
  padding-top: 10px;
}

.ProfileHeading {
  text-align: center;
  padding-bottom: 36px;
}

.PhotoContainer {
  display: inline-flex;
  justify-content: space-around;
  width: 300px;
  margin: auto;
}

.ProfileAboutContainer {
  display: inline-block;
  width: 500px;
}
